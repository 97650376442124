import '../scss/plugins/_swiper.scss'

import Swiper from 'swiper'
import { Navigation, Pagination, EffectFade, Autoplay } from 'swiper/modules'

// responsive breakpoints 	sm: 640px, md: 768px, lg: 1024px, xl: 1280px, xxl: 1536px

const modules = [Navigation, Pagination, Autoplay]

const controlsSettings = {
	modules: modules,
	pagination: {
		el: '[data-swiper-pagination]',
		type: 'custom',
		renderCustom: function (swiper, current, total) {
			function numberAppend(d) {
				return d < 10 ? '0' + d.toString() : d.toString()
			}
			return numberAppend(current) + '/' + numberAppend(total)
		},
	},

	navigation: {
		nextEl: '[data-swiper-button-next]',
		prevEl: '[data-swiper-button-prev]',
	},
}

const hero = new Swiper('[data-swiper-hero]', {
	...controlsSettings,
	modules: [...modules, EffectFade],
	effect: 'fade',
	loop: true,

	fadeEffect: {
		crossFade: true,
	},
})

const posts = new Swiper(`[data-swiper-posts]`, {
	...controlsSettings,
	spaceBetween: 24,
	slidesPerView: 1.25,

	breakpoints: {
		768: {
			slidesPerView: 2.5,
		},
		1280: {
			slidesPerView: 3.125,
		},
	},
})

document.querySelectorAll('[data-swiper-timeline-container]').forEach(container => {
	const timeline = new Swiper(container.querySelector(`[data-swiper-timeline]`), {
		modules: modules,
		grabCursor: true,

		navigation: {
			nextEl: container.querySelector('[data-swiper-button-next]'),
			prevEl: container.querySelector('[data-swiper-button-prev]'),
		},

		slidesPerView: 1.25,

		breakpoints: {
			640: {
				slidesPerView: 1.5,
			},
			1024: {
				slidesPerView: 1.65,
			},
			2000: {
				slidesPerView: 2,
			},
		},
	})
})

const testimonials = new Swiper('[data-swiper-testimonials]', {
	...controlsSettings,
	grabCursor: true,
	spaceBetween: 24,
	slidesPerView: 1,

	breakpoints: {
		640: {
			slidesPerView: 1.5,
		},
		1536: {
			slidesPerView: 2.25,
		},
	},
})

const productsSlides = document.querySelectorAll(`[data-swiper-products] li.product`)
productsSlides.forEach(slide => slide.classList.add('swiper-slide'))

const products = new Swiper(`[data-swiper-products]`, {
	...controlsSettings,
	spaceBetween: 24,
	slidesPerView: 1.25,

	breakpoints: {
		640: {
			slidesPerView: 3.25,
		},
		1024: {
			slidesPerView: 3.75,
		},
	},
})

const categories = new Swiper(`[data-swiper-categories]`, {
	...controlsSettings,
	spaceBetween: 24,
	slidesPerView: 1.25,

	breakpoints: {
		640: {
			slidesPerView: 3.25,
		},
		1024: {
			slidesPerView: 5,
		},
	},
})

const sliderGalleries = document.querySelectorAll('[data-swiper-gallery]')
if (sliderGalleries.length) {
	sliderGalleries.forEach(gallery => {
		const slidesPerView = Number(gallery.getAttribute('data-slides-per-view'))
		let slidesPerViewTablet = 1
		if (slidesPerView >= 3) {
			slidesPerViewTablet = Math.round(slidesPerView / 2)
		}
		const slidesPerViewMobile = slidesPerView > 4 ? 2 : 1
		const sliderGallery = new Swiper(gallery, {
			...controlsSettings,
			slidesPerView: slidesPerViewMobile,
			spaceBetween: 14,
			autoHeight: true,
			loop: true,
			breakpoints: {
				667: {
					slidesPerView: slidesPerViewTablet,
				},
				992: {
					slidesPerView: slidesPerView,
				},
			},
		})
	})
}
